<template>
  <div class="homepage-hero">
    <figure class="homepage-hero__image-wrapper">
      <NuxtImg
        v-if="isMobileDevice"
        preload
        src="/images/alt-hero-mobile.webp"
        format="webp"
        sizes="320px xs:320px sm:640px md:768px lg:1080px"
        densities="x1"
        alt="momentshare party"
        loading="eager"
        class="homepage-hero__image"
        width="1080"
        height="1920"
      />
      <NuxtImg
        v-else
        preload
        src="/images/alt-hero.webp"
        format="webp"
        sizes="1080px lg:1080px xl:1280px 2xl:1920px"
        densities="x1"
        alt="momentshare party"
        loading="eager"
        class="homepage-hero__image"
        width="1920"
        height="1080"
      />
    </figure>
    <div class="homepage-hero__background-wrapper" />
    <div class="homepage-hero__content">
      <h1 class="homepage-hero__title" v-html="$t('homepage_title')" />
      <div class="homepage-hero__intro">
        <p class="homepage-hero__lead">
          {{ $t('homepage_lead') }}
        </p>
        <div class="homepage-hero__actions">
          <app-button
            light
            bold
            class="homepage-hero__button"
            @click="$emit('showPricing')"
          >
            {{ $t('start_sharing') }}
          </app-button>
          <app-button
            class="homepage-hero__button homepage-hero__discover-button--mobile"
            secondary
            light
            dark-glass
            @click="$emit('showMore')"
          >
            {{ $t('discover_more') }}
          </app-button>
          <app-button
            class="homepage-hero__button homepage-hero__button homepage-hero__discover-button--desktop"
            secondary
            light
            @click="$emit('showMore')"
          >
            {{ $t('discover_more') }}
          </app-button>
        </div>
      </div>
    </div>
    <homepage-header class="homepage-hero__header" />
  </div>
</template>

<script setup>
  import { useMainStore } from '~/stores/main'

  const mainStore = useMainStore()
  const { isMobileDevice } = mainStore

  defineEmits(['showMore', 'showPricing'])
</script>

<style lang="scss">
:root {
  --homepage-hero-height: 70dvh;
}

.homepage-hero {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 var(--spacing-default);
  height: 100vh;
  background: var(--color-primary);

  @include breakpoint(md) {
    height: 800px;
    padding: var(--homepage-header-height) 0 0;
  }
}

.homepage-hero__background-wrapper {
  position: absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5634628851540616) 32%, rgba(255,255,255,0) 100%);
}

.homepage-hero__image-wrapper {
  overflow: hidden;
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 50px), 0% calc(100% - 100px));
  position: absolute;
  inset: 0;

  @include breakpoint(md) {
    clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 100px), 0% calc(100% - 200px));
  }
}

.homepage-hero__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 70% top;

  @include breakpoint(desktop) {
    object-position: center left;
  }
}

.homepage-hero__header {
  position: absolute;
  top: 0;
  left: 16px;
  right: 16px;

  @include breakpoint(desktop) {
    left: 36px;
    right: 36px;
  }
}

.homepage-hero__content {
  position: relative;
  height: 77%;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-shadow: 0px 0px 20px #000000;


  @include breakpoint(sm) {
    padding: 0 var(--spacing-default);
  }

  @include breakpoint(lg) {
    padding: 0 var(--spacing-large) 0 0;
    max-width: 70%;
    margin-top: 0;
    justify-content: flex-start;
    text-shadow: none;
  }
}

.homepage-hero__title {
  font-size: 32px;
  color: white;

  @include breakpoint(md) {
    font-size: 52px;
    line-height: 78px;
  }
}

.homepage-hero__intro {
  margin: 24px 0 0;
  display: flex;
  flex-direction: column;
}

.homepage-hero__lead {
  font-size: 18px;
  line-height: 1.5em;
  color: white;
  max-width: 700px;

  @include breakpoint(md) {
    font-size: 24px;
  }
}

.homepage-hero__actions {
  display: flex;
  margin: 48px 0 0;
  gap: 12px;
  flex-direction: column;

  @include breakpoint(xs) {
    flex-direction: row;
    gap: 24px;
  }
}
</style>

<template>
  <div class="trustpilot-link">
    <p>{{ $t('review_us_on') }}</p>
    <a
      href="https://nl.trustpilot.com/review/momentshare.io?utm_medium=trustbox&utm_source=TrustBoxReviewCollector"
      target="_blank"
    >
      <img
        src="/logos/trustpilot_logo.svg"
        height="73"
        width="300"
        alt="trustpilot"
        class="trustpilot-link__image"
        loading="lazy"
      >
    </a>
  </div>
</template>

<style lang="scss">
  .trustpilot-link {
    display: flex;
    align-items: center;
    color: white;
    gap: 4px;
  }

  .trustpilot-link__image {
    height: 22px;
    width: auto;
  }
</style>

<template>
  <div class="homepage">
    <homepage-hero
      class="homepage__hero"
      @show-more="handleShowMore"
      @show-pricing="handleShowPricing"
    />

    <div
      id="hoehetwerkt"
      ref="usps"
      class="homepage__usps"
    >
      <!--          <usp-blocks />-->
      <how-it-works dark class="homepage__how-it-works" />
    </div>


    <div ref="start" class="text-content homepage__content">
      <price-options id="aanbod" class="homepage__price-options" />
      <counters class="homepage__counters" />
    </div>

    <div class="homepage__reviews">
      <reviews id="reviews" class="homepage__reviews-block" />
    </div>


    <div class="text-content homepage__content">
      <h2 class="light-primary-title homepage__help-faq-title">
        {{ $t('help_page_title') }}
      </h2>
      <help-faq id="helpfaq" class="homepage__help-faq" />
      <contact-options id="contact" class="homepage__contact-options" />
    </div>
  </div>
</template>

<script setup>
  import { useLinks } from '~/lib/composables/links'

  const router = useRouter()
  const { t: $t } = useI18n()
  const { howItWorksLink } = useLinks()

  // const usps = ref()
  const start = ref()

  function handleShowMore() {
    router.push(howItWorksLink)
  }

  function handleShowPricing() {
    start.value.scrollIntoView({ behavior: 'smooth' })
  }

  const pageTitle = computed(() => $t('seo.home_page.title'))
  const pageDescription = computed(() => $t('seo.home_page.description'))

  useHead(
    {
      title: pageTitle,
      meta: [
        { hid: 'description', name: 'description', content: pageDescription },
      ],
    }
  )
</script>

<style lang="scss">
.homepage {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.homepage__hero {
  width: 100%;
}

.homepage__how-it-works {
  background: var(--color-primary);
}

.homepage__usps {
  display: flex;
  flex-direction: column;
  background: var(--color-primary);
  padding: 0 var(--spacing-small) 100px;
  gap: 48px;
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 50px), 0% 100%);

  @include breakpoint(sm) {
    padding: 0 var(--spacing-default) 100px var(--spacing-default);
  }

  @include breakpoint(lg) {
    padding: 0 0 256px;
    clip-path: polygon(0% 0%, 100% 0%, 100% 95%, 0% 100%);
  }
}

.homepage__price-options {
  margin: var(--spacing-large) 0;
}

.homepage__help-faq {
  margin-bottom: var(--spacing-larger);
}

.homepage__help-faq-title {
  margin-top: var(--spacing-large);
}

.homepage__reviews {
  margin: 64px 0 24px 0;
  padding: var(--spacing-large) 0;
  background: var(--color-primary);

  @include breakpoint(lg) {
    padding: var(--spacing-default) 0 var(--spacing-larger) 0;
    transform: rotate(3deg) translateX(-20px);
    width: calc(100% + 40px);
  }
}

.homepage__reviews-block {
  padding: 0 var(--spacing-default);
  max-width: 1280px;
  margin: 0 auto;

  @include breakpoint(lg) {
    transform: rotate(-3deg);
    padding: 0;
  }
}

.homepage__content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 var(--spacing-default);

  @include breakpoint(lg) {
    padding: 0;
  }
}

.homepage__counters {
  border-bottom: none;
}
</style>

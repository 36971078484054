<template>
  <header class="homepage-header">
    <text-logo />

    <app-button
      class="homepage-header__menu-button"
      secondary
      light
      aria-label="menu"
      @click="openFlyIn"
    >
      <icon class="homepage-header__menu-button-icon" name="material-symbols:menu-rounded" />
    </app-button>
    <div class="homepage-header__actions homepage-header__actions--desktop">
      <nuxt-link class="homepage-header__link" :to="pricesLink">
        {{ $t('prices_link') }}
      </nuxt-link>
      <nuxt-link class="homepage-header__link" :to="howItWorksLink">
        {{ $t('how_it_works_link') }}
      </nuxt-link>
      <nuxt-link class="homepage-header__link" :to="helpLink">
        {{ $t('help_link_text') }}
      </nuxt-link>
      <nuxt-link class="homepage-header__link" :to="aboutLink">
        {{ $t('about_link_text') }}
      </nuxt-link>
      <app-button
        light
        bold
        class="homepage-hero__button"
        @click="startOrder('?extra-option=2')"
      >
        {{ $t('start_sharing') }}
      </app-button>
      <account-button class="homepage-header__button" />
    </div>
  </header>
</template>

<script setup>
  import { FLY_IN, GENERAL_NAVIGATION_FLY_IN } from '~/lib/constants/overlay'
  import { useLinks } from '~/lib/composables/links'

  const { $overlayService } = useNuxtApp()

  const { startOrder, helpLink, aboutLink, pricesLink, howItWorksLink } = useLinks()

  function openFlyIn() {
    $overlayService.setCurrentOverlay({
      settings: {
        type: FLY_IN,
        component: GENERAL_NAVIGATION_FLY_IN,
        options: {
          transitionOrigin: 'right',
        },
      },
    })
  }
</script>

<style lang="scss">
:root {
  --homepage-header-height: 72px;

  @include breakpoint(desktop) {
    --homepage-header-height: 120px;
  }
}

.homepage-header {
  display: flex;
  align-items: center;
  height: var(--homepage-header-height);
}

.homepage-header__menu-button.app-button {
  margin: 0 0 0 auto;
  padding: 12px;

  @include breakpoint(desktop) {
    display: none;
  }
}

.homepage-header__actions {
  display: none;

  @include breakpoint(desktop) {
    margin: 0 0 0 auto;
    display: flex;
    align-items: center;
    gap: 24px;
  }
}

.homepage-header__link {
  color: white;
  font-weight: 400;
}

.homepage-header__link:hover {
  color: var(--color-secondary);
}
</style>

<template>
  <div class="review-block">
    <div class="review-block__quote">
      <div class="review-block__left">
        <icon class="review-block__icon" name="bx:bxs-quote-left" />
      </div>
      <div class="review-block__right">
        <h3 class="review-block__title" v-html="text" />
      </div>
    </div>
    <div class="review-block__author">
      <div class="review-block__icon-wrapper">
        <icon
          v-if="eventType === $t('wedding')"
          class="review-block__icon"
          name="pepicons-print:dress"
        />
        <icon
          v-if="eventType === $t('tournament')"
          class="review-block__icon"
          name="solar:cup-outline"
        />
        <icon
          v-if="eventType === $t('party')"
          class="review-block__icon"
          name="lucide:party-popper"
        />
      </div>
      <div class="review-block__author-right">
        <span class="review-block__author-name">
          <span class="text-emphasis-inverted">
            {{ name }}
          </span>
        </span>
        <span class="review-block__event-type">
          <span class="text-emphasis">
            {{ $t('review_event_type') }} {{ eventType }}
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
  const { t: $t } = useI18n()

  defineProps({
    text: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    eventType: {
      type: String,
      required: true,
    },
  })
</script>

<style lang="scss">
  .review-block {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .review-block__quote {
    display: flex;
    gap: 12px;
    background: #fff;
    padding: 16px 20px;
    border-radius: var(--default-border-radius);
    box-shadow: var(--box-shadow);
    flex-grow: 1;

    @include breakpoint(md) {
      height: 220px;
    }
  }

  .review-block__title {
    font-size: 16px;
    font-weight: 200;

    @include breakpoint(lg) {
      font-size: 18px;
    }
  }

  .review-block__author {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .review-block__icon-wrapper {
    background: var(--color-primary);
    border-radius: 50%;
    padding: 8px;
  }

  .review-block__icon {
    color: var(--color-secondary);
    font-size: 2rem;
  }

  .review-block__author-right {
    display: flex;
    flex-direction: column;
  }

  .review-block__author-name {
    font-size: 18px;
    font-weight: 400;
    color: var(--color-secondary);

    @include breakpoint(lg) {
      font-size: 22px;
    }
  }

  .review-block__event-type {
    font-size: 16px;
  }
</style>

<template>
  <div class="reviews">
    <div class="reviews__header">
      <h2 class="light-primary-title-invert reviews__title">
        {{ $t('reviews') }}
      </h2>
      <trustpilot-link />
    </div>
    <div
      ref="reviewsWrapper"
      class="reviews__wrapper"
      @touchstart="cancelLoop"
      @mouseenter="cancelLoop"
      @mouseleave="startLoop"
    >
      <review-block
        v-for="(review, index) in reviews"
        :ref="(el) => (reviewsRefs[index] = el?.$el)"
        :key="`${review.name}_${index}`"
        :name="review.name"
        :event-type="$t(review.type)"
        :text="review.text"
        class="reviews__review"
      />
    </div>
  </div>
</template>

<script setup>
  import { storeToRefs } from 'pinia'
  import { useMainStore } from "~/stores/main"

  const { t: $t } = useI18n()
  const mainStore = useMainStore()
  const {
    isMobile,
  } = storeToRefs(mainStore)

  const reviews = [
    {
      name: 'Klant',
      text: 'Het is makkelijk om de foto’s te uploaden en daarna ook weer te downloaden zodat ze bewaard kunnen blijven als het event klaar is.',
      type: 'wedding',
    },
    {
      name: 'Anette',
      text: 'Hele goede service vooraf. Top systeem dat voor een grote groep goed werkt (650 personen) én simpel is in gebruik.',
      type: 'wedding',
    },
    {
      name: 'Karina',
      text: 'Het is een heel mooi concept, niet moeilijk. Heb je vragen krijg je meteen antwoord hierop via app in mijn geval; prima! En je hebt foto\'s en filmpje vanuit elke hoek vd zaal. Iedereen was enthousiast, echt super!',
      type: 'wedding',
    },
    {
      name: 'Sjoerd',
      text: 'Heel stabiel platform, beeldmateriaal is makkelijk te uploaden zonder app. Je kunt leuke dingen doen met vormgeving zodat je het echt passend kunt maken voor je evenement. Enige nadeel is dat je de beelden niet makkelijk chronologisch kan sorteren. Dat zou een mooie toevoeging zijn.',
      type: 'wedding',
    },
    {
      name: 'Sepp',
      text: 'Echt fantastisch gewoon heel erg handig en makkelijk te creëren en echt een meerwaarde voor ons huwelijks feest. We hebben zulke leuke beelden gezien vanuit onze gasten die echt fantastisch waren en zo krijg je echt een all-round beeld van onze bruiloft. In een woord top.',
      type: 'wedding',
    },
    {
      name: 'Duimenrace.nl',
      text: 'Tijdens onze Duimenrace (liften rond het IJsselmeer) wilden wij het publiek in de binnenstad van Hoorn laten zien wat onze deelnemers meemaakten. Momentshare heeft hier in kunnen faciliteren door zowel foto als video eenvoudig door deelnemers te kunnen laten delen.',
      type: 'party',
    },
    {
      name: 'van Beckhoven',
      text: 'Zeker meerwaarde voor ons huwelijk! Super gemakkelijke manier om de gasten van onze bruiloft hun foto’s met ons te laten delen. Is veel gebruik van gemaakt. Event is makkelijk aan te maken en met de QR makkelijk te benaderen door de gasten. Aanrader!',
      type: 'wedding',
    },
    {
      name: 'Femke & Gertjan',
      text: 'Super handig! Wij hebben hele leuke foto’s van onze dag ontvangen. De mails zijn duidelijk en als je een vraag hebt is er via whatsapp echt direct contact! Super! Aanrader dus!',
      type: 'wedding',
    },
    {
      name: 'Vanessa',
      text: 'Heel duidelijk, reargeren snel op mail. Werkt perfect!!!',
      type: 'wedding',
    },
    {
      name: 'Bianca',
      text: 'Super leuk om tijdens ons meerdaagse Ferrari Club evenement foto’s te delen met de overige deelnemers !',
      type: 'party',
    },
    {
      name: 'Gino',
      text: 'Gebruiksvriendelijke website en alles heel logisch. je kan meteen aan de slag en alles werkt goed. had een paar vragen en die werden binnen een uur via WhatsApp beantwoord. Zeker een aanrader voor een feestje of evenement!',
      type: 'party',
    },
    {
      name: 'Jan',
      text: 'Fijn contact met de helpdesk ivm een foutmelding in Safari. Snel en correct geholpen.',
      type: 'wedding',
    },
    {
      name: 'Susan',
      text: 'Na oriëntatie wat de mogelijkheden zijn een mail gestuurd met vragen. Hier duidelijke antwoorden op gekregen. Het fijne is dat je met je camera alle mogelijkheden kan gebruiken. Het feest in de avond was in een donkere ruimte dus gebruik van flits heel fijn.',
      type: 'wedding',
    },
    {
      name: 'Stephanie',
      text: 'Het was erg leuk om onze bruiloft te beleven door de lens van onze gasten. De vormgeving kan mooi worden aangepast naar jouw stijlthema. Op een smartphone is het niet altijd helder dat je alle foto’s kunt zien door naar beneden te scrollen.',
      type: 'wedding',
    },
    {
      name: 'Marye',
      text: 'Snel en makkelijk geregeld en écht een toevoeging aan het feest!',
      type: 'party',
    },
    {
      name: 'Annelies',
      text: 'Super! foto\'s oploaden zonder het installeren van een app! Zeer tevreden!',
      type: 'party',
    },
    {
      name: 'Eugenio',
      text: 'Alles werkte perfect en al onze herinneringen van de mooiste dag van ons leven staat vast en we konden alles zonder problemen downloaden. Top service en top systeem!',
      type: 'wedding',
    },
  ]
  const reviewsWrapper = ref(null)
  const reviewsRefs = ref({})
  const currentIndex = ref(1)
  const timeoutId = ref(null)
  let interval = null

  function loopReviews() {
    if (reviews.length < 4 || !reviewsWrapper.value) {
      return
    }

    const behavior = currentIndex.value === 0 ? 'instant' : 'smooth'
    const numberToAdd = isMobile.value ? 1 : 3
    const isLastElement = reviews.length === currentIndex.value + numberToAdd
    const offsetLeft = reviewsRefs.value?.[currentIndex.value]?.offsetLeft
    const left = isMobile.value
      ? offsetLeft - 16
      : offsetLeft

    reviewsWrapper.value.scrollTo({
      top: 0,
      left,
      behavior,
    })

    currentIndex.value = isLastElement ? 0 : currentIndex.value + 1
  }

  onMounted(() => {
    timeoutId.value = setTimeout(() => startLoop(), 8000)
  })

  onBeforeUnmount(() => {
    clearTimeout(timeoutId.value)
    cancelLoop()
  })

  function startLoop() {
    loopReviews()

    interval = setInterval(() => loopReviews(), 8000)
  }

  function cancelLoop() {
    clearInterval(interval)
  }
</script>

<style lang="scss">
  .reviews {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .reviews__title {
    display: flex;
    align-items: center;
    line-height: 1;
    justify-content: space-between;

    @include breakpoint(sm) {
      align-items: flex-end;
    }
  }

  .reviews__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;

    @include breakpoint(md) {
      margin-top: var(--spacing-default);
    }
  }

  .reviews__wrapper {
    display: flex;
    overflow: scroll;
    overflow-y: hidden;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    gap: var(--spacing-large);

    @include breakpoint(lg) {
      gap: var(--spacing-default);
    }
  }

  .reviews__wrapper::-webkit-scrollbar {
    display: none;
  }

  .reviews__review {
    flex-shrink: 0;
    width: 100%;

    @include breakpoint(sm) {
      width: calc((100% - var(--spacing-large)) / 2);
    }

    @include breakpoint(lg) {
      width: calc((100% - (var(--spacing-default) * 2)) / 3);
    }
  }

  .list-move, /* apply transition to moving elements */
  .list-enter-active,
  .list-leave-active {
    transition: all 0.5s ease;
  }

  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }

  /* ensure leaving items are taken out of layout flow so that moving
     animations can be calculated correctly. */
  .list-leave-active {
    position: absolute;
  }
</style>
